import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

document.addEventListener("turbolinks:load", function() {
  window.openGallery = function(image){
    var pswpElement = document.querySelectorAll('.pswp')[0];
    var images = document.getElementsByClassName("gallery-image");
    if (images != null) {
      // build items array
      var items = [];
      // items.push({src: image.src, w:0, h:0})
      for (var i = 0; i < images.length; i++) {
        if (image.src === images[i].src) {
          var index = i
        }
        items.push({
            src: images[i].src,
            w: 0,
            h: 0
        })
      }

      var options = {
        index: index,
        escKey: false,
        history: false
      };

      // Initializes and opens PhotoSwipe
      var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);

      gallery.listen('imageLoadComplete', function (index, item) {
        if (item.h < 1 || item.w < 1) {
          let img = new Image()
          img.onload = () => {
            item.w = img.width
            item.h = img.height
            gallery.invalidateCurrItems()
            gallery.updateSize(true)
          }
          img.src = item.src
        }
      })

      gallery.init();
    }
  }
});
