document.addEventListener("turbolinks:load", function() {
  var selected_ids = []
  var notification = document.getElementById("dj_select_all_notification")
  var confirm_notification = document.getElementById("confirm_dj_select_all_notification")

  window.selectMultiple = function(checkbox) {
    if (checkbox.checked === true) {
      selected_ids.push(checkbox.value)
    } else {
      for( var i = 0; i < selected_ids.length; i++){
        if ( selected_ids[i] === checkbox.value) {
          selected_ids.splice(i, 1);
        }
      }
    }
    $("#selected_djs").val(selected_ids);
    if (notification != null) { notification.style.display = "none"; }
  }

  window.selectAll = function(checkbox) {
    if (checkbox.checked) {
      if (notification != null) { notification.style.display = "block"; }
    } else {
      if (notification != null) { notification.style.display = "none"; }
      if (confirm_notification != null) { confirm_notification.style.display = "none"; }
    }
    checkboxes = $(".dj-checkbox");
    check_or_uncheck(checkbox);
    $("#selected_djs").val(selected_ids);
  };

  window.selectAllLink = function() {
    event.preventDefault();
    if (notification != null) { notification.style.display = "none"; }
    if (confirm_notification != null) { confirm_notification.style.display = "block"; }
    $("#selected_djs").val("all");
    selected_ids = []
  };

  function check_or_uncheck(checkbox){
    if ( checkbox.checked ) {
      for (var i = 0; i < checkboxes.length; i++) {
        var checkbox = checkboxes[i];
        checkbox.checked = true
        if (selected_ids.includes(checkbox.value) == false) {
          selected_ids.push(checkbox.value)
        }
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        var checkbox = checkboxes[i];
        checkbox.checked = false
        for( var o = 0; o < selected_ids.length; o++){
          if ( selected_ids[o] === checkbox.value ) {
            selected_ids.splice(o, 1);
          }
        }
      }
    }
  };

  window.checkSelected = function(){
    $("#djs-table").bind("DOMSubtreeModified",function(){
      if (notification != null) { notification.style.display = "none"; }
      if (confirm_notification != null) { confirm_notification.style.display = "none"; }
      for( var i = 0; i < selected_ids.length; i++){
        check_box = document.getElementById("checkbox_" + selected_ids[i]);
        if (check_box !== null){
          check_box.checked = true;
        }
      }
    });
  }
});
