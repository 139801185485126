document.addEventListener("turbolinks:load", function() {
  clearSearchInputs();
})

function clearSearchInputs(params) {
  $( "#clear-search" ).click(function() {
    var $searchFrom = $("form")

    $searchFrom.bind("ajax:complete", function(event,xhr,status){
      $searchFrom.trigger("reset").unbind();
      return true;
    });
  });
}
