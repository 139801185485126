document.addEventListener("turbolinks:load", function() {
  window.setActive = function(div){
    var $listItems = $('div.tabs li');
    $listItems.removeClass("is-active");
    div.classList.add("is-active")
    var div_content = document.getElementById(div.id + "_content")
    var $listContent = $('div.tab-content div')
    $listContent.addClass("is-hidden")
    $listContent.removeClass("is-not-hidden")
    div_content.classList.remove("is-hidden")
    div_content.classList.add("is-not-hidden")
  }
});
