// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// After installing a 3rd party JS library through Yarn we need it to be included in webpacker
// Instructions of how to do this are dependent on each JS library and should be in their getting started docs
// Webpacker/ES6 also treats each JS file as its own scope / seperate module
// apparently this is good for de-cluttering the global scope
// this means you need to import a 3rd party library into each JS file you want to use it in
// or you can import it here and make it available in a global variable so it can be used everywhere (helpful for a library you will use everywhere like jQuery)
import jQuery from "jquery"
global.$ = jQuery;
global.jQuery = jQuery;
import selectize from "selectize";

require("custom/clear_search")
require("custom/password_strength")
require("custom/burger_menu")
require("custom/booking_calendar")
require("custom/classie")
require("custom/user_form")
require("custom/disable_link")
require("custom/datepicker")
require("custom/genres_select")
require("custom/select_multiple")
require("custom/venue_select")
require("custom/profile_tabs")
require("custom/photoswipe")
