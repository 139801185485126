document.addEventListener("turbolinks:load", function() {
  var genres = document.getElementById('selected_values');

  var $select = $('#genre_select').selectize({
    plugins: ['remove_button'],
  });

  var $select = $('#style_select').selectize({
    plugins: ['remove_button'],
  });
});
