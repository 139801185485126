import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", function() {
  $(".date-time-picker").flatpickr({
    enableTime: true,
    dateFormat: "F, d Y H:i",
    minDate: new Date()
  });
  $(".date-picker").flatpickr({
    dateFormat: "F, d Y",
    minDate: new Date()
  });
});
