document.addEventListener("turbolinks:load", function() {
  listenToUserRoleSelection();
})

function listenToUserRoleSelection() {
  $("#user_user_role_id").change(function() {

    var selectedText = $(this).find(':selected').text();
    var $agencySelect = $("#agency-select");
    var $venueSelect = $("#venue-select");
    
    if (selectedText == "Agency User") {
      showElement($agencySelect);
      hideElement($venueSelect);
    } else if (selectedText == "Venue User") {
      showElement($venueSelect);
      hideElement($agencySelect);
    } else {
      hideElement($agencySelect);
      hideElement($venueSelect);
    }
  });
}

function hideElement(element) {
  if (!element.hasClass("is-hidden")) {
    element.addClass("is-hidden");
  }
}

function showElement(element) {
  element.removeClass("is-hidden");
}
