import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

document.addEventListener("turbolinks:load", function() {
  var calendarEl = document.getElementById('calendar');
  var title_search = document.getElementById('fullcalendar_title');
  var venue_search = document.getElementById('venue_id');
  var screen_width = $(window).width()
  if (screen_width < 667) {
    var default_view = 'listMonth'
    var right_button = 'timeGridDay,listMonth'
  } else {
    var default_view = 'dayGridMonth'
    var right_button = 'dayGridMonth,timeGridWeek,listMonth'
  }
  if (calendarEl) {
    var calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin],
      defaultView: default_view,
      eventSources: [
        {
          url: '/api/v1/bookings.json',
        }
      ],
      header: {
        left: '',
        center: 'title',
        right: right_button,
      },
      displayEventTime: false,
      eventClick: function(eventObj) {
        var url = window.location.href + '/' + eventObj.event.id + '/edit';
        window.location.href = url;
      }
    });

    calendar.render();

    title_search.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        sendData(calendar, title_search, venue_search);
      }
    });

    $("#fullcalendar_search").click(function() {
      sendData(calendar, title_search, venue_search);
    });

    $("#fullcalendar_clear").click(function() {
      title_search.value = "";
      if (venue_search != null) {
        venue_search.value = "";
      }
      sendData(calendar, title_search, venue_search);
    });

    $("#calendar_next").click(function() {
      calendar.next();
      sendData(calendar, title_search, venue_search);
    });

    $("#calendar_prev").click(function() {
      calendar.prev();
      sendData(calendar, title_search, venue_search);
    });

    $("#calendar_today").click(function() {
      calendar.today();
      sendData(calendar, title_search, venue_search);
    });
  }

});

function sendData(calendar, title_search, venue_search){
  if (venue_search != null) {
    var booking_data = { title: title_search.value, start: calendar.view.activeStart, end: calendar.view.activeEnd, venue_id: venue_search.value }
  } else {
    var booking_data = { title: title_search.value, start: calendar.view.activeStart, end: calendar.view.activeEnd }
  }
  $.ajax({
    url: '/api/v1/bookings.json',
    type: 'GET',
    data: booking_data,
  }).done(function(response) {

    //remove old data
    var obj = calendar.getEventSources();
    $.each( obj, function( key, eventSource ) {
      eventSource.remove();
    });
    calendar.addEventSource(response);
    calendar.refetchEvents();
  });
}
